<template>
    <div class="visual-canvas__wrapper">
        <div ref="canvas"
             class="visual-canvas"
             :class="[modify, `visual-canvas_theme_${$dataset.propertyType}`, {'is-scroll': isMini && isFloorStage }]"
             @click="hideClue"
             v-touch:start="hideClue">
            <!-- start: Разметка для выбора квартир на рендерах -->
            <Zoom v-if="isMini && isFloorStage">
                <svg ref="svg"
                     :viewBox="[`0 0 ${renderSize.width} ${renderSize.height}`]"
                     preserveAspectRatio="xMidYMid slice"
                     class="visual-canvas__svg"
                     @click="removePanel($event)">
                    <!-- Подложка -->
                    <image :href="render.link"
                           x="0" y="0" width="100%" height="100%"></image>
                    <!-- Маски -->
                    <template v-for="element in availableElements">
                        <g v-if="element.path instanceof Array"
                           :key="element.id"
                           :id="element.id"
                           @mouseover="mouseOverHandler(element.id, element.link, $event)"
                           @mousemove="mouseMoveHandler"
                           @mouseleave="mouseLeaveHandler"
                           @click="isMini && isFloorStage ? openPopup(element) : clickHandler(element.link)">
                            <path
                                v-for="(path, key) in element.path"
                                :key="`path-${key}`"
                                :d="path"
                                :class="['visual-canvas__path',
                        {'is-booked': element.isBooked},
                        {'is-disabled': element.disabled},
                        {'is-hovered': idHoveredElement === element.id},
                        {'is-gold': isMini && isFloorStage}]"
                            ></path>
                        </g>
                        <path
                            v-else
                            :key="element.id"
                            ref="masks"
                            :id="element.id"
                            :d="element.path"
                            :class="['visual-canvas__path',
                        {'is-booked': element.isBooked},
                        {'is-disabled': element.disabled},
                        {'is-hovered': idHoveredElement === element.id},
                        {'is-gold': isMini},
                        element.type && `is-${element.type}`]"
                            @mouseover="mouseOverHandler(element.id, element.link, $event)"
                            @mousemove="mouseMoveHandler"
                            @mouseleave="mouseLeaveHandler"
                            @click="isMini && isFloorStage ? openPopup(element) : clickHandler(element.link)"></path>
                    </template>
                    <!-- Поинтеры рендера (маркеры) -->
                    <template v-for="(renderPointer, index) in renderPointers">
                        <foreignObject v-if="renderPointer.left && renderPointer.top"
                                       :key="`renderPointer-${index}`"
                                       :x="renderPointer.left"
                                       :y="renderPointer.top"
                                       width="1"
                                       height="1"
                                       :class="['visual-canvas__pointer']"
                                       requiredExtensions="http://www.w3.org/1999/xhtml">
                            <div xmlns="http://www.w3.org/1999/xhtml"
                                 :style="!isSafari && `transform: scale(${pointerScale})`"
                                 class="visual-canvas__pointer-wrapper">
                                <div xmlns="http://www.w3.org/1999/xhtml"
                                     class="visual-canvas__pointer-item visual-canvas__pointer-item_theme_render">
                                    <div class="visual-canvas__pointer-text">{{ renderPointer.text }}</div>
                                </div>
                            </div>
                    </foreignObject>
                    </template>
                    <!-- Поинтеры элементов (маркеры) -->
                    <template v-for="pointer in pointers">
                        <foreignObject v-if="pointer.left && pointer.top"
                                       :key="`pointer-${pointer.id}`"
                                       :x="pointer.left"
                                       :y="pointer.top"
                                       width="1"
                                       height="1"
                                       :class="['visual-canvas__pointer',
                                       {'is-hovered': idHoveredElement === pointer.id}]"
                                       requiredExtensions="http://www.w3.org/1999/xhtml"
                                       @mouseover="mouseOverHandler(pointer.id, pointer.link, $event)"
                                       @mouseleave="mouseLeaveHandler"
                                       @click="clickHandler(pointer.link)">
                            <div xmlns="http://www.w3.org/1999/xhtml"
                                 :style="!isSafari && `transform: scale(${pointerScale})`"
                                 class="visual-canvas__pointer-wrapper">
                                <div xmlns="http://www.w3.org/1999/xhtml"
                                     :class="['visual-canvas__pointer-item',
                                    `visual-canvas__pointer-item_type_${pointer.type}`,
                                    {'is-disabled': pointer.disabled},
                                    {'is-hovered': pointer.isHovered}]"
                                     @click="$emit('clickElement', pointer)">
                                    <div class="visual-canvas__pointer-text">{{ pointer.text }}</div>
                                    <div class="visual-canvas__pointer-plus">
                                        <IconPlus class-name="visual-canvas__pointer-icon"/>
                                    </div>
                                </div>
                            </div>
                        </foreignObject>
                    </template>
                    <!-- Тултип -->
                    <transition name="fade">
                        <foreignObject v-if="hoveredElement && hoveredElement.tooltip
                                       && !isMobile
                                       && dataStep.step !== 'floor'
                                       && !isMini"
                                       :x="Number(hoveredElement.pointer.left) + 20 || 0"
                                       :y="Number(hoveredElement.pointer.top) - 27 || 0"
                                       width="1"
                                       height="1"
                                       class="visual-canvas__tooltip"
                                       requiredExtensions="http://www.w3.org/1999/xhtml">
                            <div xmlns="http://www.w3.org/1999/xhtml"
                                 :style="!isSafari && `transform: scale(${pointerScale})`"
                                 :class="['visual-canvas__tooltip-wrapper', {'is-safari': isSafari}]">
                                <TooltipContent :data="hoveredElement.tooltip"
                                                :is-left="hoveredElement.pointer.left > renderSize.width - 360"/>
                                <div v-if="isFakePlus"
                                     :class="['visual-canvas__tooltip-plus', {'is-active': isFakePlus}]">
                                    <IconPlus/>
                                </div>
                            </div>
                        </foreignObject>
                    </transition>
                </svg>
            </Zoom>
            <svg ref="svg"
                 :viewBox="[`0 0 ${renderSize.width} ${renderSize.height}`]"
                 preserveAspectRatio="xMidYMid slice"
                 class="visual-canvas__svg"
                 @click="removePanel($event)"
                 v-else>
                <!-- Подложка -->
                <image :href="render.link"
                       x="0" y="0" width="100%" height="100%"></image>
                <!-- Маски -->
                <template v-for="element in availableElements">
                    <g v-if="element.path instanceof Array"
                       :key="element.id"
                       :id="element.id"
                       @mouseover="mouseOverHandler(element.id, element.link, $event)"
                       @mousemove="mouseMoveHandler"
                       @mouseleave="mouseLeaveHandler"
                       @click="isMini && isFloorStage ? openPopup(element) : clickHandler(element.link)">
                        <path
                            v-for="(path, key) in element.path"
                            :key="`path-${key}`"
                            :d="path"
                            :class="['visual-canvas__path',
                                {'is-booked': element.isBooked},
                                {'is-disabled': element.disabled},
                                {'is-hovered': idHoveredElement === element.id},
                                {'is-gold': isMini && isFloorStage}]"
                        ></path>
                    </g>
                    <path
                        v-else
                        :key="element.id"
                        ref="masks"
                        :id="element.id"
                        :d="element.path"
                        :class="['visual-canvas__path',
                                {'is-booked': element.isBooked},
                                {'is-disabled': element.disabled},
                                {'is-hovered': idHoveredElement === element.id},
                                {'is-gold': isMini && isFloorStage},
                                element.type && `is-${element.type}`]"
                        @mouseover="mouseOverHandler(element.id, element.link, $event)"
                        @mousemove="mouseMoveHandler"
                        @mouseleave="mouseLeaveHandler"
                        @click="isMini && isFloorStage ? openPopup(element) : clickHandler(element.link)"></path>
                </template>
                <!-- Поинтеры рендера (маркеры) -->
                <template v-for="(renderPointer, index) in renderPointers">
                    <foreignObject v-if="renderPointer.left && renderPointer.top"
                                   :key="`renderPointer-${index}`"
                                   :x="renderPointer.left"
                                   :y="renderPointer.top"
                                   width="1"
                                   height="1"
                                   :class="['visual-canvas__pointer']"
                                   requiredExtensions="http://www.w3.org/1999/xhtml">
                        <div xmlns="http://www.w3.org/1999/xhtml"
                             :style="!isSafari && `transform: scale(${pointerScale})`"
                             class="visual-canvas__pointer-wrapper">
                            <div xmlns="http://www.w3.org/1999/xhtml"
                                 :class="['visual-canvas__pointer-item', 'visual-canvas__pointer-item_theme_render']">
                                <div class="visual-canvas__pointer-text">{{ renderPointer.text }}</div>
                            </div>
                        </div>
                    </foreignObject>
                </template>
                <!-- Поинтеры элементов (маркеры) -->
                <template v-for="pointer in pointers">
                    <foreignObject v-if="pointer.left && pointer.top"
                                   :key="`pointer-${pointer.id}`"
                                   :x="pointer.left"
                                   :y="pointer.top"
                                   width="1"
                                   height="1"
                                   :class="['visual-canvas__pointer',
                                   {'is-hovered': idHoveredElement === pointer.id}]"
                                   requiredExtensions="http://www.w3.org/1999/xhtml"
                                   @mouseover="mouseOverHandler(pointer.id, pointer.link, $event)"
                                   @mouseleave="mouseLeaveHandler"
                                   @click="clickHandler(pointer.link)">
                        <div xmlns="http://www.w3.org/1999/xhtml"
                             :style="!isSafari && `transform: scale(${pointerScale})`"
                             class="visual-canvas__pointer-wrapper">
                            <div xmlns="http://www.w3.org/1999/xhtml"
                                 :class="['visual-canvas__pointer-item',
                                            `visual-canvas__pointer-item_type_${pointer.type}`,
                                            {'is-disabled': pointer.disabled},
                                            {'is-hovered': pointer.isHovered}]"
                                 @click="$emit('clickElement', pointer)">
                                <div class="visual-canvas__pointer-text">{{ pointer.text }}</div>
                                <div class="visual-canvas__pointer-plus">
                                    <IconPlus class-name="visual-canvas__pointer-icon"/>
                                </div>
                            </div>
                        </div>
                    </foreignObject>
                </template>
                <!-- Тултип -->
                <transition name="fade">
                    <foreignObject v-if="hoveredElement && hoveredElement.tooltip
                                   && !isMobile
                                   && dataStep.step !== 'floor'
                                   && !isMini"
                                   :x="Number(hoveredElement.pointer.left) || 0"
                                   :y="Number(hoveredElement.pointer.top) || 0"
                                   width="1"
                                   height="1"
                                   class="visual-canvas__tooltip"
                                   requiredExtensions="http://www.w3.org/1999/xhtml">
                        <div xmlns="http://www.w3.org/1999/xhtml"
                             :style="!isSafari && `transform: scale(${pointerScale}) translate(24px, -32px)`"
                             :class="['visual-canvas__tooltip-wrapper', {'is-safari': isSafari}]">
                            <TooltipContent :data="hoveredElement.tooltip"
                                            :is-left="hoveredElement.pointer.left > renderSize.width - 360"/>
                            <div v-if="isFakePlus"
                                 :class="['visual-canvas__tooltip-plus', {'is-active': isFakePlus}]">
                                <IconPlus/>
                            </div>
                        </div>
                    </foreignObject>
                </transition>
            </svg>
            <!-- end: Разметка для выбора квартир на рендерах -->
        </div>
        <ScrollPosition @moveIndicator="hideClue"/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import IconPlus from '../Icons/icon-plus';
import Observer from 'common/scripts/observer';
import ScrollPosition from '../ScrollPosition/ScrollPosition';
import TooltipContent from '../Tooltip/TooltipContent';
import {Utils} from 'common/scripts/utils';
import Zoom from '../Zoom/Zoom';

const observer = new Observer();

export default {
    name: 'Canvas',

    components: {
        IconPlus,
        ScrollPosition,
        TooltipContent,
        Zoom
    },

    props: {
        isMobile    : Boolean,
        isTouch     : Boolean,
        modify      : String,
        isFloorStage: Boolean
    },

    data() {
        return {
            idHoveredElement: null,
            isFakePlus      : false,
            pointerScale    : 1
        };
    },

    computed: {
        ...mapGetters({
            dataStep      : 'GET_DATA_STEP',
            isMobileClue  : 'GET_IS_MOBILE_CLUE',
            renderSize    : 'GET_CANVAS_SIZE',
            render        : 'GET_RENDER',
            elements      : 'GET_ELEMENTS',
            pointers      : 'GET_POINTERS',
            renderPointers: 'GET_RENDER_POINTERS',
            canvasShift   : 'GET_CANVAS_SHIFT',
            isMini        : 'GET_IS_MINI',
            mode          : 'GET_MODE',
            link          : 'GET_LINK'
        }),

        availableElements() {
            return this.elements.filter((element) => {
                return !element.disabled;
            });
        },

        hoveredElement() {
            return this.availableElements.find((element) => {
                return element.id === this.idHoveredElement;
            }) || null;
        },

        isTooltip() {
            return this.hoveredElement && this.hoveredElement.tooltip;
        },

        isSafari() {
            const userAgent = window.navigator.userAgent.toLowerCase();

            return Boolean(userAgent.match(/iphone/i) || userAgent.match(/ipad/i) ||
                (!userAgent.match(/chrome/i) && userAgent.match(/safari/i)));
        }
    },

    watch: {
        isTooltip() {
            if (this.isTooltip) {
                setTimeout(() => {
                    this.isFakePlus = this.isTooltip;
                }, 100);
            } else {
                this.isFakePlus = this.isTooltip;
            }
        },

        isMobile() {
            this.idHoveredElement = null;
        },

        canvasShift() {
            this.$nextTick(() => {
                this._changeScrollPosition();
            });
        }
    },

    beforeCreate() {
        observer.unsubscribe('clickNextPanel');

        observer.unsubscribe('setMaskHover');

        observer.unsubscribe('removeMaskHover');
    },

    created() {
        this._subscribes();
    },

    mounted() {
        this.$refs.canvas.addEventListener('touchmove', this._onImageScroll);
        this._changeScrollPosition();
        this._bindEvents();
        this._setPointerScale();
    },

    beforeDestroy() {
        this.$refs.canvas.removeEventListener('touchmove', this._onImageScroll);
    },

    methods: {
        ...mapActions(['changeMobileClue', 'changeCanvasShift']),

        _bindEvents() {
            const events = ['resize', 'orientationchange'];

            events.forEach((event) => {
                window.addEventListener(event, this._onResize.bind(this));
            });
        },

        _onResize() {
            this._setPointerScale();
        },

        _subscribes() {
            observer.subscribe('clickNextPanel', (link) => {
                this._route(link);
            });

            observer.subscribe('setMaskHover', (id) => {
                const elementArr = this.availableElements.filter((element) => {
                    return element.id === id;
                });

                if (elementArr.length) {
                    elementArr[0].isHovered = true;
                }
            });

            observer.subscribe('removeMaskHover', (id) => {
                const elementArr = this.availableElements.filter((element) => {
                    return element.id === id;
                });

                if (elementArr.length) {
                    elementArr[0].isHovered = false;
                }
            });
        },

        mouseOverHandler(id, link, event) {
            observer.publish('closePanels');
            this.idHoveredElement = id;
            const observerAction = this.isMobile || this.isTouch ? 'mouseoverPanel' : 'mouseoverTooltip';

            observer.publish(observerAction, {
                id,
                link,
                event,
                elements: this.availableElements
            });
        },

        mouseMoveHandler() {
            if (this.isMobile || this.isTouch) {
                return;
            }

            observer.publish('mousemoveTooltip', event);
        },

        mouseLeaveHandler() {
            this.idHoveredElement = null;
            if (!this.isMobile) {
                observer.publish('mouseleaveTooltip');
            }
        },

        clickHandler(link) {
            if (this.isMobile || this.isTouch) {
                return;
            }

            if (link.includes('commerce-')) {
                window.open(link, '_self');
            } else if (this.mode === 'minimal') {
                const url = link.length ? `${this.link}${link}${location.search}` : `/visual/${link}${location.search}`;

                window.open(url, '_blank');
            } else {
                this._route(link);
            }
        },

        openPopup(data) {
            if (!this.isMini || !this.isFloorStage || this.isMobile || this.isTouch) {
                return;
            }

            observer.publish('openBookingPopup', data);
        },

        removePanel(event) {
            if (this.$refs && this.$refs.masks && !Array.from(this.$refs.masks).includes(event.target)) {
                observer.publish('mouseleavePanel');
            }
        },

        _route(link) {
            if (this.dataStep.step === 'floor') {
                window.location = link;
            } else {
                this.$router.push({
                    path : link,
                    query: this.$route.query
                });
            }
        },

        hideClue() {
            if (!this.isMobile || !this.isMobileClue) {
                return;
            }

            this.changeMobileClue(false);
        },

        _onImageScroll() {
            let newShift = this.$refs.canvas.scrollLeft /
                (this.$refs.canvas.scrollWidth - this.$refs.canvas.clientWidth);

            if (newShift > 1) {
                newShift = 1;
            } else if (newShift < 0) {
                newShift = 0;
            }

            this.changeCanvasShift(newShift);
        },

        _changeScrollPosition() {
            this.$refs.canvas.scrollLeft = this.canvasShift *
                (this.$refs.canvas.scrollWidth - this.$refs.canvas.clientWidth);
        },

        _setPointerScale() {
            if (!this.$refs.svg) {
                return;
            }

            const {width, height} = this.$refs.svg.getBoundingClientRect();

            if (Utils.isBreakpoint(0, 960)) {
                this.pointerScale = this.renderSize.width / width;
            } else if (this.renderSize.width / this.renderSize.height >= width / height) {
                this.pointerScale = this.renderSize.height / height;
            } else {
                this.pointerScale = this.renderSize.width / width;
            }
        }
    }
};
</script>

<style lang="scss">
    @import "./Canvas.scss";
</style>
