<template>
    <transition name="fade">
        <div v-if="visible"
            :class="['visual-panel', {'visual-panel_theme_floor': dataStep.step === 'floor'}]">
            <div class="visual-panel__content">
                <button class="visual-panel__close"
                        @click="remove">
                    <IconClosePanel/>
                </button>
                <div class="visual-panel__container">
                    <!-- Данные для паркингов/кладовых -->
                    <template v-if="isMini">
                        <div class="visual-panel__header">
                            <div class="visual-panel__title"
                                 v-html="panel.title"
                                 v-if="panel.title"></div>
                            <div class="visual-panel__row">
                                <ul class="visual-panel__list">
                                    <li class="visual-panel__list-item"
                                        v-if="panel.area">
                                        <span>Площадь</span>
                                        <span v-html="panel.area" />
                                    </li>
                                    <li class="visual-panel__list-item"
                                        v-if="panel.quarterShort">
                                        <span>Квартал</span>
                                        <span v-html="panel.quarterShort" />
                                    </li>
                                </ul>
                            </div>
                            <div class="visual-panel__row">
                                <div class="visual-panel__price">
                                    <span v-if="panel.hidePrice">По&nbsp;запросу</span>
                                    <template v-else>
                                        <span v-if="panel.price && panel.price.current">
                                            {{ convertPriceDigit(panel.price.current) }} ₽
                                        </span>
                                        <span v-if="panel.price && panel.price.mortgage"
                                              class="visual-panel__mortgage">
                                                от {{ convertPriceDigit(panel.price.mortgage) }} ₽ в месяц в ипотеку
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <div v-if="panel.linkText"
                                 class="visual-panel__row">
                                <button type="button"
                                        class="button button_width_block button_theme_small"
                                        @click="goBooking">
                                    <span class="button__inner">
                                        <span class="button__text"
                                              v-html="panel.linkText"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </template>
                    <!-- Данные для основных шагов -->
                    <template v-else-if="dataStep.step !== 'floor'">
                        <div class="visual-panel__header">
                            <div class="visual-panel__title"
                                 v-html="panel.title"></div>
                            <div v-if="panel.comment"
                                 class="visual-panel__comment"
                                 v-html="panel.comment"></div>
                            <div v-if="panel.building"
                                class="visual-panel__comment"
                                v-html="panel.building"></div>
                            <div v-if="panel.quarter"
                                 class="visual-panel__comment"
                                 v-html="panel.quarter"></div>
                            <div v-if="panel.deadline"
                                 class="visual-panel__comment"
                                 v-html="panel.deadline"></div>
                        </div>
                        <ul v-if="panel.params && panel.params.length && $dataset.propertyType !== 'commerce'"
                             class="visual-panel__params">
                            <li class="visual-panel__param visual-panel__param_theme_title">
                                <div class="visual-panel__param-type">Спален</div>
                                <div class="visual-panel__param-value">Стоимость</div>
                                <div class="visual-panel__param-amount">В продаже</div>
                            </li>
                            <li v-for="(item, index) in panel.params"
                                :key="index"
                                class="visual-panel__param">
                                <div v-if="item.title"
                                     class="visual-panel__param-type">{{ rooms(item.title) }}</div>
                                <div v-if="panel.hidePrice || item.hidePrice"
                                     class="visual-panel__param-value"
                                     v-html="'По&nbsp;запросу'"></div>
                                <div v-else-if="item.price"
                                     class="visual-panel__param-value"
                                     v-html="`от&nbsp;${convertPriceRank(item.price)}&nbsp;млн&nbsp;₽`"></div>
                                <div v-if="item.amount"
                                     class="visual-panel__param-amount"
                                     v-html="item.amount"></div>
                            </li>
                        </ul>
                        <ul v-else
                             class="visual-panel__params visual-panel__params_theme_commerce">
                            <li class="visual-panel__param visual-panel__param_theme_title">
                                <div class="visual-panel__param-value">Назначение</div>
                                <div class="visual-panel__param-amount">Площадь</div>
                            </li>
                            <li v-for="(item, index) in panel.params"
                                :key="index"
                                class="visual-panel__param">
                                <div v-if="item.title"
                                     class="visual-panel__param-type">{{ item.title }}</div>
                                <div v-if="item.area"
                                     class="visual-panel__param-value">{{ item.area }}&nbsp;м2</div>
                            </li>
                        </ul>
                        <div v-if="panel.link && panel.linkText"
                             class="visual-panel__next">
                            <button type="button"
                                    class="button button_width_block button_theme_small"
                                    @click="toNextStep(panel.link)">
                                <span class="button__inner">
                                    <span class="button__text"
                                          v-html="panel.linkText"></span>
                                </span>
                            </button>
                        </div>
                    </template>
                    <!-- Данные для шага этажа -->
                    <template v-else>
                        <div class="visual-panel__row" v-if="panel.isBooked">
                            <div class="visual-panel__lock">
                                <IconLock />
                            </div>
                        </div>
                        <div class="visual-panel__row">
                            <div class="visual-panel__option">
                                <div class="visual-panel__option-value">
                                    <template v-if="panel.area">
                                        <span class="area">{{ panel.area }}<span class="area-units"> м²</span></span>
                                    </template>
                                </div>
                                <div class="visual-panel__option-value">
                                    <span v-if="panel.hidePrice">По&nbsp;запросу</span>
                                    <template v-else>
                                        <span v-if="panel.price && panel.price.current">
                                            {{ convertPriceDigit(panel.price.current) }} ₽
                                        </span>
                                        <span v-if="panel.price && panel.price.mortgage"
                                              class="visual-panel__option-value_theme_accent">
                                            {{ convertPriceDigit(panel.price.mortgage) }} ₽ в месяц
                                        </span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="visual-panel__row">
                            <div class="visual-panel__option">
                                <div class="visual-panel__option-value"
                                     v-html="panel.title"></div>
                                <div v-if="panel.floor"
                                     class="visual-panel__option-value"
                                     v-html="panel.floor"></div>
                            </div>
                            <div v-if="panel.quarter"
                                 class="visual-panel__option">
                                <div class="visual-panel__option-value"
                                     v-html="panel.quarter"></div>
                            </div>
                        </div>
                        <div v-if="panel.options && panel.options.length"
                             class="visual-panel__row">
                            <ul class="visual-panel__options">
                                <li v-for="(option, index) in panel.options"
                                    :key="index"
                                    class="visual-panel__option-item">
                                    <img alt="" :src="option">
                                </li>
                            </ul>
                        </div>
                        <div v-if="panel.link && panel.linkText"
                             class="visual-panel__row">
                            <button type="button"
                                    class="button button_width_block button_theme_small"
                                    @click="toNextStep(panel.link)">
                                <span class="button__inner">
                                    <span class="button__text"
                                          v-html="panel.linkText"></span>
                                </span>
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import IconClosePanel from '../Icons/icon-close-panel';
import IconLock from '../Icons/icon-lock';
import {mapGetters} from 'vuex';
import Observer from 'common/scripts/observer';
import {Utils} from 'common/scripts/utils';

const observer = new Observer();

export default {
    name: 'Panel',

    components: {
        IconClosePanel,
        IconLock
    },

    props: {
        isMobile: Boolean
    },

    data() {
        return {
            visible: false,
            panel  : {
                params: []
            }
        };
    },

    computed: {
        ...mapGetters({
            dataStep: 'GET_DATA_STEP',
            isMini  : 'GET_IS_MINI',
            mode    : 'GET_MODE',
            link    : 'GET_LINK'
        })
    },

    watch: {
        dataStep() {
            this.remove();
        },

        isMobile() {
            if (this.visible) {
                this.remove();
            }
        }
    },

    mounted() {
        this._subscribes();
    },

    methods: {
        _subscribes() {
            observer.subscribe('mouseoverPanel', (data) => {
                if (this.visible) {
                    this.remove();
                }
                this._insert();
                this._setData(data);
            });

            observer.subscribe('mouseleavePanel', () => {
                this.remove();
            });
        },

        _insert() {
            this.visible = true;
        },

        remove() {
            this.visible = false;
        },

        _setData(data) {
            const targetId = Number(data.id);

            const currentData = data.elements.find((element) => {
                const id = Number(element.id);

                return id === targetId ? element : false;
            });

            this.panel = {
                id      : targetId,
                type    : data.type,
                link    : data.link || false,
                isBooked: currentData.isBooked,
                ...currentData.tooltip
            };
        },

        convertPriceDigit(number) {
            return Utils.convertToDigit(number);
        },

        convertPriceRank(price) {
            return Utils.convertToDigit(Utils.convertToRank(price, 1000000).toFixed(2));
        },

        toNextStep(link) {
            if (this.mode === 'minimal') {
                const url = link.length ? `${this.link}${link}${location.search}` : `/visual/${link}${location.search}`;

                window.open(url, '_blank');
            } else {
                observer.publish('clickNextPanel', link);
            }
        },

        goBooking() {
            this.remove();
        },

        rooms(title) {
            return parseInt(title);
        }
    }
};
</script>

<style lang="scss">
    @import "Panel.scss";
</style>
